body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Font Family Mulish */
.mul {
  font-family: "Mulish", sans-serif;
}

.choose-plan {
  color: #0000a0 !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}
.choose-plan-wrapper:hover {
  background-color: #0000a0 !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}
.choose-plan-wrapper:hover .choose-plan {
  color: #ffff !important;
}

/* color */
.col-a0 {
  color: #0000a0;
}
.col-20 {
  color: #000020;
}
.col-35 {
  color: #000035;
}
.col-2e {
  color: #2e2ead;
}
.col-a6 {
  columns: #a6a6a6;
}
.col-ff{
  color: #ffff
}
.col-b1{
  color: #b1ea7d;
}
/* backgouns color */
.bc-ff {
  background-color: #ffff;
}
.bc-f5 {
  background-color: #f5f6fc;
}
.bc-f1{
  background-color: #F1F1EA;
}

 .bc-33{
  background-color: #336B00
}
/* font-wight */
.f7 {
  font-weight: 700;
}
.f4 {
  font-weight: 400;
}
.fw-b {
  font-weight: bold;
}

/* font size */
.rem1 {
  font-size: 1rem;
}
.px36 {
  font-size: 36px;
}
.px28 {
  font-size: 28px;
}

.px24 {
  font-size: 24px;
}
.px23 {
  font-size: 23px;
}
.px20 {
  font-size: 20px;
}
.px16 {
  font-size: 16px;
}
.px12 {
  font-size: 12px;
}
/* width */
.pak {
  width: 20%;
}
.sp-9 {
  width: 9%;
}
/* border radius */
.bd12 {
  border-radius: 12px;
}
.bd20 {
  border-radius: 20px;
}
/* border Right */
.bor-right{
border-right: 1px solid #b1ea7d;
}
/* Text Align Center */
.tx-align {
  text-align: center;
}
/* Cursor Pointer */
.cur {
  cursor: pointer;
}

.bor {
  border: 1px solid #ffff;
  border-radius: 12px;
}
.bor-btn {
  border-radius: 4px;
  border: 1px solid #0000a0;
  padding-top: 8px;
  padding-bottom: 8px;
}

.pay-page {
  background-color: #e5e5e5;
  height: 95vh;
}
.mobile {
  display: none;
}

/* new design */

.backimg1 {
  background-image: url("./assets/images/Component-11-2.png");
  width: 100%;
  height: 95vh;
  background-size: cover;
}
.backimg2 {
  background-image: url("./assets/images/doc-image.jpg");
  width: 100%;
  height: 95vh;
  background-size: cover;
}
.backimg3 {
  background-image: url("./assets/images/dash-bg-new.jpg");
  width: 100%;
  height: 95vh;
  background-size: cover;
}
.backimg4 {
  background-image: url("./assets/images/dash-bg-new.jpg");
  width: 100%;
  height: 95vh;
  background-size: cover;
}
.backimg5 {
  background-image: url("./assets/images/doc-image.jpg");
  width: 100%;
  height: 95vh;
  background-size: cover;
}
.welcome-container {
  height: 100%;
  margin-top: 15vh;
  background-color: #fefffe;
  border: 3px solid #4b9840;
  border-radius: 8px;
}
.welcome-container p {
  font-size: 1.5vw;
  color: rgb(45, 43, 43);
}
.welcome-container2 {
  height: 100%;
  margin-top: 5vh;
  background-color: #fefffe;
  border: 3px solid #4b9840;
  border-radius: 8px;
}
.green-btn {
  border: 2px solid #4b9840;
  border-radius: 8px;
  color: #4b9840;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding-top: 7px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}
.green-btn:hover {
  background-color: #4b9840;
  color: #ffff;
}

.signup-btn {
  height: 40px;
  width: 170px;
  border: 2px solid #4b9840 !important;
  background-color: #4b9840 !important;
  color: #ffff;
  font-size: 1.2rem;
  text-align: center;
  padding-top: 3px;
  border-radius: 8px;
}
.rs-radio-inner::after {
  background: none !important;
}
.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
  background-color: #4b9840 !important;
}

.input-size {
  width: 60% !important;
}

.next {
  border: 2px solid #4b9840;
  height: 40px;
  width: 135px;
  border-radius: 7px;
  text-align: center;
  padding-top: 3px;
  font-size: 20px;
  color: #4b9840;
  cursor: pointer;
}
.next:hover {
  color: #ffff;
  background-color: #4b9840;
}
/* mobile view */
@media (max-width: 550px) {
  .pak {
    width: 80%;
  }
  .pay-page {
    height: auto;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .px24 {
    font-size: 18px;
  }
  .welcome-container p {
    font-size: 3vw;
  }
  .green-btn {
    font-size: 3vw;
    padding-top: 8px;
    height: 40px;
  }
  .backimg1 {
    height: 94vh;
  }
  .px28 {
    font-size: 19px;
  }
  .px23 {
    font-size: 14px;
  }
  .backimg4 {
    height: auto;
  }
  .backimg5 {
    height: auto;
  }
  .next {
    width: 100px !important;
  }
  .input-size {
    width: 90% !important;
  }
}
/* Admin */
.nav-text {
  font-size: 16px;
  margin-left: 15px;
  color: #336B00;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.nav-style {
  color: #336B00;
  font-size: 25px;
  margin-left: 30px;
}
.hov {
  cursor: pointer;
  height: 50px;
  padding-top: 10px;
  color: #000035;
}
.hov:hover {
  background-color: #336B00;
}
.hov:hover .nav-text{
  color: #e5e5e5 ;
}
.hov:hover .nav-style{
  color: #e5e5e5 ;
}
.act-link {
  background-color: #336B00;
}
.act-link .nav-text{
  color: #e5e5e5 ;
}
.act-link .nav-style{
  color: #e5e5e5 ;
}
.nav-head {
  margin-left: 9px !important;
  font-size: 18px;
  font-family: sans-serif;
  color: #1c2579;
}
.bo-right {
  border-right: 1px solid #D5E3CA;
  height: 94vh;
  padding-left: 0px;
  background-color: #D5E3CA;
}
.bo-top {
  border-top: 1px solid #f5f6f8;
}
.act-bt {
  height: 30px;
  width: 90px;
  background-color: #40d400;
  text-align: center;
  border-radius: 7px;
  color: white;
  padding-top: 3px;
  cursor: pointer;
}
.cancle-bt {
  height: 30px;
  width: 90px;
  background-color: #f91b36;
  text-align: center;
  border-radius: 7px;
  color: white;
  padding-top: 3px;
  cursor: pointer;
}
.page-head {
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 900;
  color: #1c2579;
  margin-left: 10px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
.page-size {
  height: 88vh;
  overflow-y: auto;
  width: 100%;
}
.btn-update {
  height: 40px;
  width: 100px;
  background-color: #1c2579;
  color: white;
  border-radius: 4px;
}
.bc-f5 {
  background-color: #f5f6f8;
}

.flowy {
  overflow-y: auto;
  height: 88vh;
}
.bar-border {
  /* border-right: 1px solid #e5e5e5; */

  height: 94vh;
}
.top-bo {
  border-top: 1px solid #e5e5e5;
}
.user-header {
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 900;
  color: #1c2579;
}
.icon-box{
  height: 44px;
    width: 44px;
    background-color: white;
    margin-top: 10px;
}
.bell-style{
  margin-left: 12px;
    margin-top: 12px;
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 7px;
}
.icon-box2{
  height: 45px;
    width: 50px;
    background-color: white;
    margin-top: 5px;
    border-radius: 5px;
}
.icon-style2{
  font-size: 25px;
  margin-top: 10px;
    margin-left: 8px;
    color: #336B00;
}
/* .btn-list{
  height: 40px;
  width: 136px;
  font-size: 15px;
} */
.btn-delete{
  height: 40px;
  width: 91px;
  font-size: 14px;
  background-color: #FFF0EE;
  color: #BA1A1A;
  border-radius: 5px;
}
.btn-deactivate{
  height: 40px;
  width: 118px;
  font-size: 14px;
  background-color: #F8FFEB;
  color: #336B00;
  border-radius: 5px;
}
.btn-activate{
  height: 40px;
  width: 103px;
  font-size: 14px;
  background-color: #336B00;
  color: #FFFFFF;
  border-radius: 5px;
}
.logout{
  font-size: 14px;
  color: #336B00;
  margin-left: 7px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.person-text{
  color: #777872;
}
.person-info{
  color: #2F312C;
}
.edit-text{
  color: #777872;
font-size: 14px;
margin-left: 4px;

}
.edit-box{
  height: 40px;
  width: 119px;
  border: 1px solid #E3E3DC;
  border-radius: 5px;
  padding: 9px;
}

/*  */
