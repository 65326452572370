.App {
  text-align: center;
}
.text-end{
  text-align: end;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* ..................New Design........... */
.header{
  width: 102%;
  height: 80px;
  background: #0253d7; 
  background: -moz-linear-gradient(left,  #0253d7 0%, #6daefc 73%, #6daefc 75%); 
  background: linear-gradient(to right,  #0253d7 0%,#6daefc 73%,#6daefc 75%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0253d7', endColorstr='#6daefc',GradientType=1 ); 
}
.bt-signUp{
  width: 100px;
  height: 44px;
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 16px;
}
.bt-login{
  width: 125px;
  height: 44px;
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 16px;
  margin-left: 25px;
}
.bt-signUp:hover{
  border: 1px solid #75CA4E;
  background: #75CA4E;
  color: #1A1A1A;
}
.bt-login:hover{
  border: 1px solid #75CA4E;
  background: #75CA4E;
  color: #1A1A1A;
}
.agencyLine{
  height: 30px;
  width: 205px;
  border: 1px solid rgba(86, 171, 47, 0.2);
  background-color: #eaf5e3;
}
.bt-patient{
  width: 118px;
  height: 48px;
  border: 1px solid #75CA4E;
  /* background: transparent; */
  background-color: #75CA4E;
  color: #ffffff;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.bt-medicalEx{
  width: 218px;
  height: 48px;
  border: 1px solid #75CA4E;
  /* background: transparent; */
  background-color: #75CA4E;
  color: #ffffff;
  border-radius: 5px;
  font-size: 20px;
  margin-left: 25px;
  cursor: pointer;
}
.bt-patient:hover{
  background: #047c04;
;
}
.bt-medicalEx:hover{
  background: #047c04;
  
}
.bt-UR{
  width: 151px;
  height: 48px;
  border: 1px solid #005500;
  background: transparent;
  color: #005500;
  border-radius: 5px;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.bt-UR:hover{
  background: #75CA4E;
  border: 1px solid #75CA4E;
  color: #FFFFFF;
}
/* Font Size */
.fz14{
  font-size: 14px;
}
.fz16{
  font-size: 16px;
}
.fz20{
  font-size: 20px;
}
.fz32{
  font-size: 32px;
}
.fz36{
  font-size: 36px;
}
.fz56{
  font-size: 56px;
}
/* Font weight */
.fw3{
  font-weight: 300;
}
.fw4{
  font-weight: 400;
}
.fw5{
  font-weight: 500;
}
.fw6{
  font-weight: 600;
}
/* Color */
.cl00{
  color: #0052D4;
}
.cl1A{
  color: #1A1A1A;
}
.clff{
  color: #FFFFFF;
}
/* Margin left */
.ml-35{
  margin-left: 35%;
}
.ml-25{
  margin-left: 25%;
}
.m-auto{
  margin-left: auto;
  margin-right: auto;
}
.mt-30{
  margin-top: 30px;
}
.text-center{
  text-align: center
}
/* background color */
.bc2s{
  background: #0253d7; 
  background: -moz-linear-gradient(left,  #0253d7 0%, #6daefc 73%, #6daefc 75%); 
  background: linear-gradient(to right,  #0253d7 0%,#6daefc 73%,#6daefc 75%);
}
.bc-green2{
  background: rgb(86,171,47); 
  background: -moz-linear-gradient(top,  rgba(86,171,47,1) 47%, rgba(168,224,99,1) 100%); 
  background: -webkit-linear-gradient(top,  rgba(86,171,47,1) 47%,rgba(168,224,99,1) 100%); 
  background: linear-gradient(to bottom,  rgba(86,171,47,1) 47%,rgba(168,224,99,1) 100%); 
}
.bc-fb{
  background-color: #fbf8f8;
}
.bc-ff{
background-color: #ffffff;
}
/* Position */
.pa{
  position: absolute;
}
.pr{
  position: relative;
}
/*  */
.homeParagraph li{
  margin-top: 10px;
}

.back-btn{
 
  padding-top: 20px;
}
.login-box{
  margin-left: 10%;
 
}
.input-field{
  width: 100%;
  height: 40px;
  border: 1px solid #145BD9;
  border-radius: 5px;
  margin-left: 20px;
  padding-left: 8px;
}
.forgetPaswd{
  font-size: 14px;
  font-weight: 400;
  color: #56AB2F;
  /* position: absolute;
  left: 380px;
  top: 65px; */
}
.uderline{
  border-bottom: 1px solid #e8e8e8;
}

/* Backgroun Images */
.img2 {
  background-image: url("./assets/image2.png");
  background-position: top center;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}
.img3 {
  background-image: url("./assets/image3.png");
  background-position: top center;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}
.img4 {
  background-image: url("./assets/image4.png");
  background-position: top center;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}
.img5 {
  background-image: url("./assets/image5.png");
  background-position: top center;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}
.img6 {
  background-image: url("./assets/image6.png");
  background-position: top center;
  background-size: cover;
  height: 100vh;
 
  background-repeat: no-repeat;
}
.img7 {
  background-image: url("./assets/image7.png");
  background-position: top center;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}
.transcation-row tr:nth-child(even) {background: #f2f8f2}


.text-boxe {
  width: 70%;
  margin: auto;
  border: 1px solid grey;
  border-radius: 3px;
  padding: 8px;
  resize: vertical;
  font-size: small;
}

.page-btn{
  width: 91px;
  height: 48px;
  color: #78be51;
  border: 1px solid #78be51;
  border-radius: 5px;
  font-size: 20px;
  background-color: transparent;
}


.page-btn:hover {
  color: #1A1A1A;
  background: rgb(86,171,47); 
  background: -moz-linear-gradient(top,  rgba(86,171,47,1) 47%, rgba(168,224,99,1) 100%); 
  background: -webkit-linear-gradient(top,  rgba(86,171,47,1) 47%,rgba(168,224,99,1) 100%); 
  background: linear-gradient(to bottom,  rgba(86,171,47,1) 47%,rgba(168,224,99,1) 100%); 
}

.consultant-header{
  border-bottom: 2px solid #fbf8f8;
}


.onlineConstHeader{
  height: 69px;
  background: #0253d7; 
  background: -moz-linear-gradient(left,  #0253d7 0%, #6daefc 73%, #6daefc 75%); 
  background: linear-gradient(to right,  #0253d7 0%,#6daefc 73%,#6daefc 75%);
}
.pay-btn{
  height: 40px;
  width: 169px;
  border: 1px solid rgb(86,171,47);
  border-radius: 5px;
  background: rgb(86,171,47); 
  background: -moz-linear-gradient(top,  rgba(86,171,47,1) 47%, rgba(168,224,99,1) 100%); 
  background: -webkit-linear-gradient(top,  rgba(86,171,47,1) 47%,rgba(168,224,99,1) 100%); 
  background: linear-gradient(to bottom,  rgba(86,171,47,1) 47%,rgba(168,224,99,1) 100%);
}

.userheader{
  font-size: 20px;
  color: #FFFFFF;
  padding-top: 22px;
  padding-left: 30px;
  height: 80px;
  background: #0253d7; 
  background: -moz-linear-gradient(left,  #0253d7 0%, #6daefc 73%, #6daefc 75%); 
  background: linear-gradient(to right,  #0253d7 0%,#6daefc 73%,#6daefc 75%);
}

.picbox{
  height: 345px;
  width: 100%;
  border: 1px solid #f2f8f2;
}
.picbox img {
  width: 100%;
  height: 100%;
}

.userheader2{
  font-size: 20px;
  color: #1A1A1A;
  padding-top: 22px;
  padding-left: 30px;
  height: 80px;
  border-bottom: 2px solid #f2f8f2;
}
.chatheader{
  height: 62px;
  border-bottom: 2px solid #f2f8f2;
}
.borderLeftRight{
  border-left: 2px solid #f2f8f2;
  border-right: 2px solid #f2f8f2;
  height: 100vh;
}

/* Mobile */
.bt-menu-login{
  width: 252px;
  height: 78px;
  border: 1px solid #005500;
background-color: #FFFFFF;
  color: #005500;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 16px;
}
.bt-menu-signup{
  width: 252px;
  height: 78px;
  border: 1px solid #75CA4E;
  background-color: #75CA4E;
  color: #005500;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 16px;
}
.home1{
  width: 100%;
  height: 267px;
  padding-right: 15px;
  padding-left: 15px;
  background-image: url('../src/assets/image1.png');
  background-size: cover;

}

@media screen and (max-width: 1230px) {
  .fz56{
  font-size: 40px;
  }
}
@media screen and (max-width: 600px) {
  .mt8{
    margin-top: 8px;
  }
 .fz32{
font-size: 24px;
 }
 .fz20{
  font-size: 16px;
 }
 .input-field{
  width: 100%;
  margin-left: 0px;
 }
 .ml330{
  margin-left: 0px;
}
.pay-btn{
  width: 120px;
}
.bt-patient{
  width: 94px;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
}
.bt-medicalEx{
  width: 150px;
  height: 40px;
  font-size: 16px;
  margin-left: 15px;
}


.mt-30{
  margin-top: 20px;
}
}
/* .............................. */

.vital-statis{
  position: absolute;
    right: 33%;
    top: 5%;
    font-size: 40px;
    color: white;
}
.vital-img {
  background-image: url("./assets/image2.png");
  height: 88vh;
  background-size: cover;
  background-repeat: no-repeat;
  
}

/* chat  */
.suggestionsItems {
  margin-left: 8px;
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #a7cefc;
}
.suggestionsItems:hover {
  background-color: #75CA4E;
}

