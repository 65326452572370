.container-fluid {
  padding-right: 0;
  padding-left: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  color: rgb(37, 37, 37);
}
.backdrop {
  background-image: url("../images/Component-11-2.png");
  width: 100%;
  height: 95vh;
  background-size: cover;
}
/* .backdrop-dash {
  background-image: url("../images/dash-bg-new.jpg");
  width: 100%;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 120% center;
} */
.bluebg {
  background-image: url("../images/martha-dominguez-de-gouveia-nMyM-1.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.backdrop2 {
  background-image: url("../images/Group@2x.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.doc-images {
  width: 80%;
  margin-top: 0vh;
  height: auto;
}
.doc-images2 {
  width: 100%;
  margin-top: 0vh;
  height: auto;
}
.right-welcome-container {
  height: 100%;
  margin-top: 15vh;
  text-align: center;
  background-color: #fefffe;
  border: 3px solid #4b9840;
  border-radius: 8px;
}
.right-welcome-container p {
  font-size: 1.5vw;
  color: rgb(45, 43, 43);
}
.darkblue-btn,
.darkblue-btn:hover {
  background: #061890;
  color: #fff;
}
.purplish-blue {
  background-color: #2c399f;
  color: #fff;
}

.light-purplish-blue {
  background-color: #757baa;
  color: #fff;
}
.box-shadow-hover:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.dark-blue-border {
  border: 3px solid #063164;
}
.darkblue-color {
  color: #063164;
}
.cursor {
  cursor: pointer !important;
}
.blue-btn {
  border: 2px solid #4b9840 !important;
  color: #4b9840 !important;
  text-decoration: none !important;
  font-size: 1.2rem;
}
.blue-btn:hover {
  color: #fff !important;
  text-decoration: none !important;
  background-color: #4b9840 !important;
}
.doc-div {
  margin-top: 50vh;
  margin-left: 10px;
}
.right-pane {
  width: 100%;
  height: 100vh;
}
.backdrop-signup {
  background-image: url("../images/ani-kolleshi-7jjnJ-QA9fY-unsplash@2x.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.backdrop-doc {
  background-image: url("../images/doc-image.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 120% center;
  height: 100vh;
  width: 100%;
}
.backdrop-signup2 {
  background-image: url("../images/bg-image.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 120% center;
  height: 100vh;
  width: 100%;
}
.text-boxes {
  width: 100%;
  margin: auto;
  border: none;
  border-radius: 3px;
  padding: 8px;
  resize: vertical;
  font-size: small;
}
.text-boxe {
  width: 100%;
  margin: auto;
  border: 1px solid grey;
  border-radius: 3px;
  padding: 8px;
  resize: vertical;
  font-size: small;
}
textarea {
  resize: none;
  width: 100%;
  height: 100px;
  color: rgb(36, 35, 35);
  border: none;
  border-radius: 3px;
  padding: 10px;
}
.line-break::after {
  content: "";
  width: 50px;
  height: 10px;
  background: #222;
  display: block;
  transform: rotate(125deg);
  border-radius: 12px;
  left: 15px;
  top: 33px;
  position: absolute;
}
.backdrop-login {
  background-image: url("../images/login.png");
  background-size: contain;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.backdrop-login2 {
  background-image: url("../images/Path@2x.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* height: 100vh;
    width: 40%; */
}
.err {
  font-size: small;
  color: red;
  display: none;
}
.succ {
  font-size: small;
  color: green;
  display: none;
}
.cont {
  width: 100%;
  height: 100vh;
  /* background-image: url("../images/martha-dominguez-de-gouveia-nMyM7fxpokE-unsplash@2x.png"); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* background-color: black; */
  margin: auto;
  overflow-y: auto;
}
.dash {
  /* width: 100%;
  min-height: 100vh; */
  /* background-image: url("../images/martha-dominguez-de-gouveia-nMyM7fxpokE-unsplash@2x.png"); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.whole-box {
  background-color: #fff;
  height: 100vh;
  width: 100%;
  margin: auto;
  margin-top: 0px;
  border-radius: 3px;
  border-radius: 3px;
}
.assigned-doc {
  width: 100%;
  height: 55px;
  color: white;
  background-color: indigo;
  cursor: pointer;
}
.assigned-doc:hover {
  background-color: #25224d;
}
.chat-box {
  height: 100vh;
  width: 100%;
  /* background-color: #f2f2f2; */
  border-radius: 3px;
}
.chat-header {
  height: 61px;
  width: 100%;
  /* background-color:rgb(46, 59, 95); */
}

.chat-status {
  border: 1px solid #061890;
  border-radius: 14px;
}

.rs-btn-toggle-inner {
  color: #061890;
  text-align: center;
}

.rs-btn-toggle::after {
  background-color: #061890;
  width: 25px;
  height: 26px;
  left: 0px;
  top: 0px;
  border-radius: 25px;
  /* margin-left: -25px !important; */
}

.rs-btn-toggle {
  height: 28px;
  min-width: 85px;
}

.side-bar-chat {
  background-color: rgb(207, 207, 207);
  height: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.no-messages {
  width: 40%;
  background-color: white;
  margin: auto;
  text-align: center;
}
.chat {
  background-color: white;
  height: 82.5%;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  border-top-right-radius: 3px;
}
/* .fetched-message{
    font-size:13px;
} */
.bubble {
  position: relative;
  background: #2798cc;
  min-height: 20px;
  border-radius: 3px;
  color: white;
  margin-top: 20px;
  padding: 5px;
  font-size: 15px;
  min-width: 30%;
  max-width: 90%;
}
.text-div {
  width: 100%;
  /* height:81px; */
  /* padding: 10px; */
  /* background-color:rgb(46, 59, 95); */
  /* margin-top:10px; */
}

.chat-textbox {
  border: 1px solid #0908a0;
  padding-left: 10px;
  padding-right: 10px;
}

.sender {
  float: right;
}
.receiver {
  float: left;
  background-color: #417f9b;
}
.chatowner {
  font-size: 12px;
  color: rgb(46, 59, 95);
}
.message {
  width: 75%;
  height: 50px;
  background: #f2f3f8;
  border-radius: 0px 15px 15px 15px;
  font-size: 16px;
  font-weight: 600;
}
.message.sender.doc-panel {
  border-radius: 15px 0px 15px 15px;
}
.doc-details {
  /* color: white; */
  /* width: 90%; */
  /* margin-left: 5%; */
  /* margin-right: 5%; */
  height: 100%;
  /* text-align: center; */
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
}
.buttons {
  width: 100px;
  border: none;
  height: 40px;
  background-color: white;
  color: black;
  border-radius: 3px;
}
.front-buttons {
  width: 100%;
  margin-top: 10px;
  border: none;
  background-color: white;
  color: black;
  min-height: 40px;
  border-radius: 3px;
}
.signup-form-q {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 80px;
  background-color: #f3f3f3;
  height: auto;
  padding: 20px;
  border-radius: 3px;
  font-size: small;
}
.assignment {
  width: 90%;
  min-height: 300px;
  background-color: #f3f3f3;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 150px;
  border-radius: 3px;
  padding: 10px;
}
@media (max-width: 500px) {
  .assignment {
    width: auto !important;
    min-height: 300px;
    background-color: #f3f3f3;
    margin-left: 1% !important;
    margin-right: 1% !important;
    margin-top: 0px !important;
    border-radius: 3px;
    padding: 10px;
  }
  .customRoundImg {
    width: 100% !important;
    height: auto !important;
  }
  .rs-modal-dialog {
    width: 55% !important;
    margin: 0 1%;
  }
}
#call {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#patient-call {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.menu {
  color: white;
  margin-top: 20px;
  float: right;
  margin-right: 10px;
  cursor: pointer;
}
.menu-items {
  font-size: large;
}
.menu-items:hover {
  color: rgb(46, 59, 95);
  cursor: pointer;
  font-weight: bolder;
}
.profileBox {
  width: "95%";
  height: auto;
  background-color: white;
  font-size: large;
}
.profile-pic {
  max-width: 90%;
  margin: auto;
  border-radius: 100%;
  height: 27%;
  max-height: 90%;
  width: 90%;
}
/* TABS */
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  /* border: 1px solid #061890; */
}
.nav-link {
  width: 33.33%;
  font-size: 11px;
  text-align: center;
  color: #222;
  /* border-right: 1px solid #061890 !important; */
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: unset;
  border: none;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #1A1A1A;
  background-color: #8DC26F;;
  border-color: #8DC26F;;
  border-radius: 0;
}

.area-class {
  width: 100%;
  min-height: 200px;
  /* background-color:#7096D1; */
  margin-top: 6vh;
  text-align: center;
  border-radius: 3px;
}
.buttonsp {
  width: 200px;
  height: 40px;
  border-radius: 3px;
  color: black;
  background-color: white;
}
.icons {
  color: white;
  margin-top: 10px;
  margin-left: 20px;
}
.right-welcome-container span {
  font-size: 30px;
  font-weight: bolder;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.customRoundImg {
  width: 40%;
  height: 360px;
  border-radius: 20px !important;
  display: inline-block;
}

@media screen and (max-width: 1199px) {
  .right-welcome-container p {
    font-size: 3.5vw;
    /* color: white; */
    height: auto;
  }
  .right-welcome-container span {
    /* font-size:25px;
        font-weight: bolder; */
  }
  .right-welcome-container {
    width: 90vw;
    margin: auto;
    margin-top: 5vh;
    border-radius: 3px;
    padding: 15px;
    /*  border: 1px solid white;
        
        border-radius: 3px;
        height: auto;
        background-color:#f4f4f4; */
  }
}

.vertical-nav {
  min-width: 5.5rem;
  width: 5.5rem;
  height: 95vh;
  /* position: fixed; */
  top: 100px;
  left: 0;
  /* box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1); */
  transition: all 0.4s;
}

.page-content {
  width: calc(100% - 5.5rem);
  margin-left: 5.5rem;
  transition: all 0.4s;
}
@media (max-width: 500px) {
  .page-content {
    width: calc(100% - 5.5rem);
    margin-left: 0rem;
    width: 100%;
  }
  .h2 {
    font-size: 21px;
  }
  .rs-btn-toggle {
    height: 28px;
    min-width: auto;
  }
  .responsive-drawer {
    min-width: 390px;
  }
}

/* for toggle behavior */

#sidebar.active {
  margin-left: -17rem;
}

#content.active {
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -17rem;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
    margin: 0;
  }
  #content.active {
    margin-left: 17rem;
    width: calc(100% - 17rem);
  }
  .backdrop-signup2,
  .backdrop-doc {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.w-30 {
  width: 120px;
}
.saq-hide {
  display: none;
}
@media (max-width: 500px) {
  .saq-width {
    width: auto !important;
  }

  .card-footer {
    padding: 5px !important;
  }
  .text-right {
    text-align: none !important;
  }
  .h4 {
    font-size: 12px !important;
  }
  .saq-ovr {
    max-height: 64vh !important;
  }
  .saq-hide {
    display: block !important;
  }
  .saq-show {
    display: none;
  }
  .chat-box {
    height: 80vh !important;
  }
  .text-div {
    padding: 0px !important;
  }
  .area-class {
    margin-top: -4vh !important;
  }
  .rs-drawer-left.rs-drawer-xs {
    width: 240px !important;
  }
  .saq-doc-chat1 {
    display: none;
  }
  .saq-pat-chat1 {
    display: none;
  }
  .saq-font {
    display: block !important;
  }
  .saq-doc-chat2 {
    display: none;
  }
  .saq-call {
    width: 77px !important;
    margin-left: -25px !important;
  }
  .saq-vital {
    display: block !important;
  }
  .nav-link {
    display: inline;
    padding: 0.3rem 1.4rem;
  }
  .saq-header {
    margin-top: -20px;
  }

  .responsive-chatbox input {
    width: 80% !important;
  }
  .responsive-chatbox button {
    width: 20% !important;
  }
}
.saq-font {
  position: absolute;
  right: 135px;
  top: -30px;
}
.saq-font {
  display: none;
}
.saq-vital {
  display: none;
}
.saq-btn {
  font-size: 12px;
  background-color: rgb(6, 24, 144);
  border: 1px solid rgb(6, 24, 144);
  color: white;
  width: 100%;
  margin-left: auto;
  text-align: center;
  border-radius: 10px;
}
.btn-hover:hover {
  text-decoration: underline;
  color: #f44314;
}

/* ...................................... */

.BGBlueColor,
.BGBlueColor:hover {
  background: #0253d7; 
  background: -moz-linear-gradient(left,  #0253d7 0%, #6daefc 73%, #6daefc 75%); 
  background: linear-gradient(to right,  #0253d7 0%,#6daefc 73%,#6daefc 75%);
}
.BGBlueColor-text {
  color: #063164;
}
.fade{
  opacity: 1;
}